a {
  text-decoration: none;
  color: inherit;
}

/* Estilização da barra de rolagem no Google Chrome */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #f1e7df;
}

::-webkit-scrollbar-thumb {
  background-color: #5e5758;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #332d2e;
}

/* Estilização da barra de rolagem no Firefox */
html {
  scrollbar-color: #5e5758 #f1e7df;
}
